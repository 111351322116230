.navbar {
    width: 100%;
    padding: 1.5rem !important;
  
    .navigation-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      
      &-icon {
        width: 3rem;
        height: 3rem;
      }
  
      &-logo {
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
  
        .logo {
          width: 85%;
          height: auto;
          align-self: center;
        }
  
        .icon {
          font-size: 28px;
          margin-left: 1rem;
        }
      }

      &-title {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
          width: 15px;
          height: auto;
          position: absolute;
          left: 20px;
        }

        h1 {
          margin-bottom: 0;
        }
      }

      &-languages {
        align-self: flex-end;
      }
    }
}

@media only screen and (min-device-width: 767px) and (max-width: 1281px){
    .navbar {
        display: flex !important;
        flex-direction: column;
        justify-content: space-between !important;
        align-items: flex-start;
        padding: 1rem !important;
    
        .navigation-menu {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
    
          .languages {
            align-self: center !important;
            h6 {
              margin-bottom: 0;
            }

            &:hover {
              cursor: pointer;
            }
          }

          &-links {
            display: flex;
            margin: 0 .5rem 0 0;
            padding: 0;
            list-style: none;

            img {
              width: 2rem;
              height: auto;
              margin: 0 .5rem;
            }

            img:hover {
              cursor: pointer;
            }

            li {
              padding: .2rem .5rem;
              background-color: $pink;
              font-weight: 900;
              color: $white;
              margin: 0 .5rem;
              font-size: 1.2rem;
              cursor: pointer;
            }

            .pink-m {
              background-color: $pinkM;
            }

            .black {
              background-color: $black;
            }
          }
    
          &-icon {
            margin-right: .5rem;
          }
    
          &-logo {
            display: flex;
            align-self: center;
            align-items: center;
            justify-content: space-between;
            margin-top: 1rem;
      
            .logo {
              width: 35% !important;
              height: auto;
              align-self: center;
            }
      
            .icon {
              font-size: 28px;
              margin-left: 1rem;
            }
          }
        }
      }
}

@media only screen and (min-width: 1282px) {
  .navbar {
      display: flex !important;
      justify-content: space-between !important;
      padding: 1.5rem 5rem !important;
      border-bottom: 5px solid $pink;
  
      .navigation-menu {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
  
        .languages {
          align-self: center !important;
          h6 {
            font-size: 1.2rem;
            margin-bottom: 0;
          }
        }

        &-links {
          display: flex;
          margin: 0 .5rem 0 0;
          padding: 0;
          list-style: none;

          img {
            width: 2rem;
            height: auto;
            margin: 0 .5rem;
          }

          img:hover {
            cursor: pointer;
          }

          li {
            padding: .2rem .5rem;
            background-color: $pink;
            font-weight: 900;
            color: $white;
            margin: 0 .5rem;
            font-size: 1.2rem;
            cursor: pointer;
          }

          .pink-m {
            background-color: $pinkM;
          }

          .black {
            background-color: $black;
          }
        }
  
        &-icon {
          margin-right: .5rem;
        }
  
        &-logo {
          display: flex;
          align-self: center;
          align-items: center;
          justify-content: space-between;
          margin-top: 1rem;
    
          .icon {
            font-size: 28px;
            margin-left: 1rem;
          }
        }
      }
    }
}
