.account {
    min-height: 100vh;
    background-color: $greyS !important;

    img {
        width: 100%;
        height: auto;
    }

    &-text {
        min-height: 85vh;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p, small {
            text-align: justify;
            hyphens: auto;
            font-weight: 500;
        }

        small {
            font-size: 12px !important;
        }

        input {
            width: 100%;
            border: none;
            border-radius: 20px;
            padding: 10px;
            margin-bottom: .5rem;
        }

        .bubl {
            display: flex;
            align-items: baseline;
            img {
                width: 6rem;
                margin-left: .4rem;
            }
        }
    }

    &-button {
        display: flex;
        justify-content: center;

        &-next {
            border: 4px solid $greyLL !important;
            border-radius: 3rem !important;
            padding: .5rem 1rem !important;
            background-color: $greyS !important;
            color: $greyXL !important;
            align-self: center;
            margin-top: 2rem;
            min-width: 10rem;
            position: relative;
    
            &-text {
                margin-right: 10px;
                font-weight: 900;
            }
    
            &-icon {
                color: $greyLL;
                position: absolute;
                right: .5rem;
                top: .7rem;
            }
        }
    }
}

.pink-border {
    border-top: 1px solid $pink !important;
    width: 100%;
    opacity: 1;
    position: absolute;
    bottom: 1rem;
}

@media only screen and (min-device-width: 767px) {
    .account {
        &-banner {
            width: 50%;
            margin: 0 auto;
        }
    
        &-text {
            width: 50%;
            margin: 0 auto;
    
            p {
                font-size: 24px;
            }

            small {
                font-size: 20px;
            }
    
            input {
                padding: 1rem;
                font-size: 18px;
            }
        }

        &-button {
            &-next {
                border: 4px solid $pink !important;
                border-radius: 3.5rem !important;
                padding: 1rem 1.4rem !important;
                margin-top: 2rem;
                width: 12rem;
        
                &-text {
                    margin-right: 10px;
                    font-size: 20px;
                    font-weight: 800;
                }
        
                &-icon {
                    color: $pink;
                    position: absolute;
                    right: .8rem;
                    top: 1.2rem;
                }
            }
        }
    }
}