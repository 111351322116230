$black: #000;
$white: #fff;
$pink: #ff5f90;
$pinkM: #f3aabe;
$pinkS: #ffeaf0;
$greyXL: #545454;
$greyLL: #8e9091;
$greyL: #bfbfbf;
$greyM: #d9d9d9;
$greyS: #e9e9e9;
$greyXS: #f5f6f7;
$green: #32CD32;
$yellow: #d5d717;
$blue: #5271ff;