.menu {
    &-content {
        background-color: $greyS;
        border-top: 2px solid $pink;
        min-height: 90vh;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px 0;

        .start-button {
            border: 2px solid $greyXL !important;
            border-radius: 3rem !important;
            padding: .5rem 1rem !important;
            margin-left: auto;
            margin-right: auto;
            background-color: $white !important;
            color: $greyXL !important;
            position: relative;
            width: 11rem;
      
            &-text {
              font-size: 18px;
              font-weight: 800;
            }
        }

        &-social {
            display: flex;
            flex-direction: column;

            .start-button {
                border: 2px solid $greyL !important;
                background-color: $greyS !important;

                .icon {
                    opacity: .7;
                }

                &-text {
                    margin-left: 10px;
                }
            }

            .share {
                width: 10rem;
                height: auto;
            }

            &-links {
                display: flex;
                justify-content: center;
                margin-top: 1rem;
                
                img {
                    width: 2rem;
                    height: 2rem;
                    margin: 0 .5rem;
                }
            }
        }

        &-options {
            ul {
                margin: 0;
                padding: 0;

                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;
                    background-color: $white;
                    border-top: 1px solid $pinkS;
                    padding: 10px;

                    h2 {
                        margin-bottom: 0;
                    }

                    img {
                        width: auto;
                        height: 1rem;
                        transform: rotate(180deg);
                    }
                }
            }
        }

        &-button {
            display: flex;
            flex-direction: column;
            align-items: center; 
        }
    }

    &-page {
        background-color: $white;
        position: relative;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .pink-border {
            border-top: 1px solid $pink !important;
            width: 100%;
            opacity: 1;
            margin-top: 1rem;
            position: relative;
        }    
        
        &-header {
            position: absolute;
            top: 1rem;
            padding: .5rem;
            width: 100%;
            z-index: 2000;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;

            h1 {
                margin-bottom: 0;
                font-weight: 900;
            }

            img {
                width: auto;
                height: 1rem;
            }

            .languages {
                align-self: center;
            }
        }

        &-content {
            padding-top: 3rem;

            &-text {
                padding: 2rem;

                h5 {
                    text-transform: uppercase;
                    font-weight: 800;
                }

                a {
                    text-decoration: underline !important;
                    color: $blue !important;
                }
            }

            &-dr {
                border-top: 2px solid $greyM;
                padding: 1rem;

                &-card {
                    max-width: 65%;
                    text-align: center;
                    img {
                        height: 7rem;
                        width: auto;
                    }
                }
            }

            &-contact {
                padding: 2rem;
                .contact-image {
                    width: 100%;
                    height: auto;
                }

                h3 {
                    margin: 3rem 0;
                }

                .contact-details {
                    display: flex;
                    margin-top: .5rem;

                    img {
                        width: auto;
                        height: 1.5rem;
                        margin-right: .5rem;
                    }
                }
            }

            &-next {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding: 0 2rem 2rem 0;

                :first-child {
                    width: 6rem;
                    height: auto;
                }
                :last-child {
                    height: 2rem;
                    width: auto;
                    margin-left: 1rem;
                }
            }

            .accordion {
                padding: 2rem;

                &-item {
                    border: none;
                    border-top: 1px solid $pink !important;
                    border-radius: 0;
                }

                &-body {
                    display: flex;
                    align-items: flex-start;

                    img {
                        width: 2rem;
                        border: 1px solid $black;
                        padding: 5px;
                        margin-right: 1rem;
                    }
                }

                .accordion-button:not(.collapsed) {
                    color: $white !important;
                    background-color: $pink !important;
                }

                .accordion-button:not(.collapsed)::after {
                    background-image: var(--bs-accordion-btn-icon) !important;
                }

            }

            small {
                display: block;
                margin-bottom: 10px;
                font-size: 14px;
            }

            .faq-image {
                width: 100%;
                height: auto;
            }
        }
    }

    &-languages {
        position: absolute;
        right: 20px;
    }
}