.homepage-desktop {
    &-banner {
      border-top: 2px solid $pink;
      width: 100%;
      height: auto;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-bottom: 5px solid $black;
  
      img {
        height: 100%;
        width: auto;
      }
  
      h1 {
        color: $white;
        background-color: $pink;
        display: inline-block;
        margin-top: .2rem;
        padding: .1rem;
      }
  
      &-scan {
        display: flex;
        flex-direction: column;
  
        :first-child {
          width: 10rem;
          height: auto;
        }
  
        :last-child {
          margin-top: 1rem;
          width: 18rem;
          height: auto;
        }
      }
    }
  
    &-description {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5rem 5rem;
      background-color: $greyXS;
  
      &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 2rem;
        max-width: 80%;
  
        img {
          max-width: 500px;
          width: 50%;
          height: auto;
        }
  
        .left {
          margin-left: 5rem;
        }
  
        &-element {
          max-width: 50%;
          padding: 2rem 0;
  
          .pink {
            color: $pink;
            font-weight: 900;
  
            strong {
              color: $black;
              font-size: inherit;
            }
          }
  
          h4 {
            margin-top: .7rem;

            strong {
              font-size: inherit;
            }
          }
  
          ul {
            list-style: none;
            padding-left: 0;

            li {

                display: flex;
                img {
                    width: 10%;
                    height: 10%;
                }
            }
          }
        }
      }
    }
  
    &-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  
    &-review {
      &-content {
        background-color: $greyS;
        border-top: 5px solid $pink;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 1rem 6rem;
  
        h2 {
          color: $pink;
          font-weight: 300;
        }
  
        .review-images {
          width: 100%;
          display: flex;
          justify-content: space-around;
          margin-top: 4rem;
  
          .review-image {
            padding: 20px;
            width: 26.2vw;
            height: auto;
            margin: 0 2vw;
  
            span {
              font-weight: 900;
            }
  
            &-star {
              display: flex;
              justify-content: flex-end;
  
              img {
                width: 2vw;
                height: auto;
              }
            }
          }
        }
      }
    }
  
    &-contact {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5rem 10rem;
      border-top: 5px solid $greyS;
      background-color: $greyXS;
  
      .contact-image {
        width: 70%;
        height: auto;
        margin: 0 1rem;
      }
    }
  
    &-start {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: flex-end;
      max-width: 250px;
  
      .start-button {
        border: 3px solid $greyL !important;
        border-radius: 45px !important;
        padding: 15px !important;
        margin-left: auto;
        margin-top: 25px;
        background-color: $white !important;
        color: $greyXL !important;
        position: relative;
        width: 250px;
    
        &-text {
            font-weight: 800;
        }
      }
    
      &-price {
        max-width: 110px;
        text-align: center;
        color: $greyXL;
        margin-top: 10px;
      }
    }
  
    &-page {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  
      &-content {
        flex: 1;
        padding: 2rem 5rem;
        background-color: $greyXS;
        border-bottom: 2px solid $black;
  
        h1 {
          background-color: $pinkM;
          color: $white;
          font-weight: 900;
          display: inline;
          padding: .5rem;
        }
  
        h4 {
          margin-top: 2rem;
        }
  
        &-center {
          display: flex;
          flex-direction: column;
          align-items: center;
  
          h2 {
            strong {
              color: $pink;
              font-size: inherit;
            }
          }
        }
  
        .contact {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
    
          &-phone {
            width: auto;
            height: 27rem;
            margin-right: 2rem;
          }
  
          &-main {
            max-width: 20%;
            margin: 2rem 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
  
            strong {
              font-weight: 900;
              font-size: inherit;
            }
          }
  
          &-details {
            display: flex;
            align-items: flex-end;
            margin-bottom: 1rem;
  
            h5 {
              margin: 0;
            }
  
            &-row {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-top: 1rem;
  
              &-icon {
                width: 2rem;
                height: auto;
              }
            }
          }
  
          &-icon {
            width: 2rem;
            height: auto;
            margin-right: .5rem;
          }
        }
  
        .contact-image {
          width: auto;
          height: 8rem;
        }
      }
    }
  
    .scan {
      margin-top: 10px;
      img {
        width: 8rem;
        height: auto;
      }
  
      strong {
        color: $pink;
      }
    }
  
    .pink-border {
      border: 6px solid $pink;
      opacity: 1;
    }
}

@media only screen and (min-device-width: 767px) and (max-width: 1281px) {
    .homepage-desktop {
        &-description {
            padding: 1rem;
            &-row {
                flex-direction: column;
                padding: 0;

                img {
                    width: 50%;
                    margin-bottom: 20px;
                }

                &-element {
                    max-width: 100%;
                    padding: 2rem 0;

                    ul li img {
                        width: 3rem;
                        height: 3rem
                        ;
                    }
                }

                .left {
                    margin-left: 0;
                }          
            }

            .reverse {
              flex-direction: column-reverse;
            }
        }

        &-contact {
            padding: 3rem;
        
            .contact-image {
              width: 80%;
              height: auto;
              margin: 0 1rem;
            }
        }

        &-review {
            &-content {
                padding: 1rem .5rem 3rem;

                .review-images {
                    margin-top: 2rem;
            
                    .review-image {
                        padding: .2rem;
                        width: 30%;
                        height: auto;
                        margin: 0 .5rem;
                    }
                }
            }
        }
    }
}
