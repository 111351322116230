@import "./style/variables.scss";
@import "./style/Account.scss";
@import "./style/Navbar.scss";
@import "./style/Menu.scss";
@import "./style/HomepageDesktop.scss";

@font-face {
  font-family:"Open Sans Regular";
  src:url("./assets/fonts/OpenSans-Regular.eot") format("eot"),url("./assets/fonts/OpenSans-Regular.woff") format("woff"),url("./assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight:normal;
  font-style:normal;
}

* {
  font-family:"Open Sans Regular";
  font-size: 16px;
}

body {
  display: flex;
  justify-content: center;
  width: 100%;
}

#root {
  max-width: 1920px;
  width: 100%;
}

.homepage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;

    .carousel {
      height: auto;
      width: 100%;

      &-indicators {
        margin-bottom: 0;
        button {
          background-color: $pink;
        }
      }

      &-inner {
        min-height: 70vh;

        .carousel-item {
          height: 90%;
        }

        .carousel-item-1 {
          height: 100%;
          border-top: 2px solid $pink;
          border-bottom: 2px solid $black;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }

        h1 {
          color: $white;
          background-color: $pink;
          display: inline-block;
          margin-top: .2rem;
          padding: .1rem;
          font-weight: 300;
        }

        h2, p {
          text-align: center;
        }

        .people {
          width: 100%;
          height: auto;
        }

        .carousel-item-2 {
          &-image {
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: linear-gradient(rgba(255,95,144,0.30), rgba(255,95,144,0.30)), url(./assets/images/Homepage_2.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            max-height: 400px;
            height: 30vh;

            h2 {
              background-color: $pink;
              padding: 2rem 1rem;
              color: $white;
              font-weight: 300;
            }
          }

          &-text {
            padding: 30px 20px;

            h2 {
              font-weight: 300;
            }

            p {
              font-weight: 300;
            }
          }
        }

        .carousel-item-3 {
          &-image {
            background-color: $white;
            border-bottom: 2px solid $black;
            border-top: 2px solid $pink;
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 400px;
            height: 38vh;
            padding: 2rem;

            .consult-example {
              height: 100%;
              width: auto;
              margin: 0 1rem;
            }

            .consult-example-icon {
              color: $greyXL;
              margin-top: 5vw;
            }
          }

          &-text {
            padding: 20px;

            h2 {
              font-weight: 300;
            }

            p {
              font-weight: 300;
            }
          }
        }

        .consult-example-image {
          img {
            width: auto;
            height: 10rem;
            margin: 0;
          }

          :first-child {
            position: absolute;
            top: 10rem;
            left: 5rem;
          }

          :last-child {
            position: absolute;
            top: 5rem;
            right: 5rem;
          }
        }

        .carousel-item-image {
          height: 33vh;
          background-color: $greyS;
          border-bottom: 2px solid $black;
          border-top: 2px solid $pink;
          padding: 20px 10px;
          text-align: center;

          h2 {
            color: $pink;
            text-align: center;
          }

          .carousel {
            height: 85%;

            &-indicators {
              display: none !important;
            }

            &-control-prev, &-control-next {
              display: none !important;
            }

            &-inner {
              height: 100%; 

              &-item {
                height: 100%;
              }
            }
          }

          .review-image {
            width: auto;
            height: 35%;
            margin: 0 auto;
          }
        }
      }

      .carousel-item-medical-team-image {
        padding: 10px;
        
        .carousel {
          height: 100%;

          &-item {
            height: 100%;
          }
        }
      }

      &-control-prev, &-control-next {
        width: 50%;
        margin-bottom: 10px;
        z-index: 2;

        &-icon {
          align-self: flex-end;
          background-image: url('./assets/images/Arrow.svg');
          width: 50px;
          height: 20px;
        }
      }

      &-control-next {
        &-icon {
          transform: rotate(180deg);
        }
      }

      &-indicators {
        z-index: 1;

        button {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $greyL;
        }

        .active {
          background-color: $pink;
        }
      }
    }

    &-main {
      &-1 {
        h2 {
          background-color: $pink;
          margin-top: 20px;
          display: inline;
          line-height: 1.5;
        }
      }
    }

    .start-button {
      border: 2px solid $greyL !important;
      border-radius: 3rem !important;
      padding: .5rem 1rem !important;
      margin-left: auto;
      margin-right: auto;
      background-color: $white !important;
      color: $greyXL !important;
      position: relative;
      width: 11rem;

      &-text {
        font-weight: 800;
      }
    }

    &-start-price {
      max-width: 150px;
      text-align: center;
      margin-top: 5px;
    }
}

.navigation-logo, .footer-logo {
  height: 60px;
  width: auto;

  &:hover {
    cursor: pointer;
  }
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem;
  height: 12vh;

  .footer-links {
    display: flex;

    .menu-content-social-links {
      margin-top: 0 !important;

      img {
        opacity: 1 !important;
      }
    }
  }
}

.languages {
  display: flex;
  align-self: center;

  h6 {
    margin-bottom: 0;
  }

  :first-child {
    padding-right: .5rem;
    border-right: 1px solid $black;
  }

  :last-child {
    padding-left: .5rem;
  }

  .active {
    color: $pink;
  }

  &:hover {
    cursor: pointer;
  }
}

.bold {
  font-weight: 900;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

@media only screen and (min-device-width: 768px) and (max-width: 1281px) {
  .navigation-logo, .footer-logo {
    height: 50px;
    width: auto;
    margin: 1rem;
  }
}